// 行车任务信息列表表头
export const carRecordColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '制定人',
      width: 120,
      align: 'center',
      dataIndex: 'managerUser.name',
    },
    {
      title: '任务执行时间',
      width: 400,
      align: 'center',
      scopedSlots: { customRender: 'executeTime' },
    },
    {
      title: '执行人',
      width: 120,
      align: 'center',
      dataIndex: 'executeUser.name',
    },
    {
      title: '所属部门',
      width: 200,
      align: 'left',
      dataIndex: 'department.name',
    },
    {
      title: '执行车辆',
      width: 140,
      align: 'center',
      dataIndex: 'carInfo.platNumber',
    },
    {
      title: '执行内容',
      width: 200,
      align: 'left',
      dataIndex: 'summary',
      ellipsis: true,
      customRender: (text, record) => {
        const time = `${record.summary ? record.summary : '-'}` //es6写法
        return time
      },
    },
    {
      title: '任务状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '任务制定时间',
      width: 200,
      align: 'center',
      dataIndex: 'dateCreated',
    },
  ]
}

// 行车任务状态
export const statusList = function () {
  return [
    { value: 'NOT', label: '待执行' },
    { value: 'DOING', label: '执行中' },
    { value: 'COMPLETED', label: '已完成' },
  ]
}

// 行车任务类型
export const typeList = function () {
  return [
    { value: 'GIVEN', label: '下达' },
    { value: 'AUTO', label: '自主' },
  ]
}
