<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">
          <a-row>
            <a-col :span="22">基础信息</a-col>
            <a-col :span="2">
              <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                {{expand?'收起':'展开'}}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-col>
          </a-row>
        </div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo" v-if="expand">
        <a-col :span="8" style="height: 30px;">
          <span>任务状态：</span>
          <a-tag v-if="formItem.status === 'NOT'">未开始</a-tag>
          <a-tag v-else-if="formItem.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
          <a-tag v-else-if="formItem.status === 'DOING'" color="#87d068">执行中</a-tag>
          <a-tag v-else>-</a-tag>
        </a-col>
  <!--      <a-col :span="8">
          <span>任务类型：</span>
          <span v-if="formItem.type === 'AUTO'">自主</span>
          <span v-else-if="formItem.type === 'GIVEN'">下发</span>
          <span v-else>-</span>
        </a-col>-->
        <a-col :span="8" v-if="formItem.status === 'DOING' || formItem.status === 'COMPLETED'">
          <span>执行人：</span>{{ formItem.executeUser.name || '-' }}
        </a-col>
        <a-col :span="8"><span>制定人：</span> {{ formItem.managerUser.name || '-' }}</a-col>
        <a-col :span="8" v-if="formItem.status === 'DOING' || formItem.status === 'COMPLETED'"
        ><span>执行时间：</span> {{ formItem.startTime }} ~ {{ formItem.endTime || '至今' }}
        </a-col>
        <a-col :span="8" v-if="formItem.status === 'DOING'"
        ><span>速度：</span> {{ formItem.carInfo.speed }} km/h
        </a-col>
        <a-col :span="8" v-if="formItem.status === 'DOING' || formItem.status === 'COMPLETED'">
          <span>已耗时：</span>{{ formItem.durationStr }}
        </a-col>
        <a-col :span="8" v-if="formItem.status === 'COMPLETED'"
        ><span>行车公里：</span> {{ formItem.mileage }} km
        </a-col>
        <a-col :span="16"><span>执行内容：</span> {{ formItem.summary }}</a-col>
        <a-col :span="24" v-if="formItem.status === 'COMPLETED'"
        ><span>开始位置：</span>{{ formItem.recordTrail.startLocation }}
        </a-col>
        <a-col :span="24" v-if="formItem.status === 'COMPLETED'"
        ><span>完成位置：</span>{{ formItem.recordTrail.endLocation }}
        </a-col>
      </a-row>
      <div v-if="formItem.status === 'DOING' || formItem.status === 'COMPLETED'" class="details-title">
        <div class="details-title-text">
          行车轨迹
          <a-button style="margin-left: 20px;" size="small" type="primary" icon="sync" @click="handleRunTrail()">
            轨迹回放
          </a-button>
        </div>
        <i class="details-title-text-i"></i>
      </div>
      <div v-if="formItem.status === 'DOING' || formItem.status === 'COMPLETED'" class="details-content-rests">
        <div class="cover-show-container" style="margin: 0px;">
          <div :id="recordMapContainerId" class="map-container" :style="{height: styleMapHeight + 'px'}"/>
        </div>
      </div>
      <div class="hide">{{ mapStyle }}</div>
    </div>
    <a-modal
      title="电话上报详情"
      :visible="damageReportDetailModal"
      :maskClosable="true"
      centered
      :footer="false"
      @ok="viewDamageReportOk"
      @cancel="closeDamageReportModal">
      <damage-report-details ref="viewRef"></damage-report-details>
    </a-modal>
    <a-modal
      title="巡检详情"
      :visible="xjDetailModal"
      centered
      width="800px"
      :footer="false"
      :maskClosable="true"
      @ok="viewInspectionOk"
      @cancel="closeInspectionModal">
      <xunjian-passed ref="passedRef" :mapHeight="350"></xunjian-passed>
    </a-modal>
  </a-skeleton>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { carRecordFormVO } from './common/carRecordFormVO'
  import UUID from '../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import '../../../core/amap'
  import mapStyle from '../../map/composite/mixins/map-style'
  import DamageReportDetails from '../../ticket/damagereport/Detail'
  import XunjianPassed from '../../map/composite/XunjianPassed'

  export default {
    name: 'CarRecordDetail',
    components: { XunjianPassed, DamageReportDetails },
    mixins: [mapStyle],
    data () {
      return {
        expand: true,
        ifSub: false,
        formItem: carRecordFormVO(),
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        /*地图*/
        recordMapContainerId: new UUID().id,
        recordMap: undefined,
        marker: null,
        pathArray: null,
        warningArray: null,
        damageReportDetailModal: false,
        xjDetailModal: false,
        item: {
          id: ''
        },
        styleMapHeight: 300
      }
    },
    methods: {
      toggle () {
        this.expand = !this.expand
        if (this.expand) {
          this.styleMapHeight = 300
        } else {
          this.styleMapHeight = 600
        }
      },
      /**
       * 初始化地图
       */
      initMap () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.recordMap = new AMap.Map(this.recordMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/normal',
            center: [106.230909, 38.487193],
            zoom: 11
          })
          this.buildMapOtherLayer()
          if (
            this.formItem.recordTrail &&
            this.formItem.recordTrail.position &&
            this.formItem.recordTrail.position !== '[]'
          ) {
            this.drawLine(this.formItem.id, this.formItem.recordTrail.position)
            this.drawWarningPoints()
          }
        })
      },
      /*加载数据*/
      loadData (id) {
        this.formItem = carRecordFormVO()
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            // console.info('-------------', data)
            Object.assign(this.formItem, data.body)
            if (this.formItem.instanceId !== null) {
              this.item.id = this.formItem.instanceId
            }
            if (data.body.recordTrail) {
              // console.log('111')
              this.formItem.recordTrail = data.body.recordTrail
            } else {
              this.formItem.recordTrail = {
                id: null,
                position: null,
                startLocation: '',
                endLocation: ''
              }
            }
            this.loadWarningData(id)
          }
        })
      },
      /*显示预警数据*/
      viewWarningData () {
      },
      /*加载预警数据*/
      loadWarningData (id) {
        console.log(id)
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.warning,
          params: { id },
          noTips: true,
          success: (data) => {
            this.warningArray = data.body
            this.initMap()
          }
        })
      },
      handleRunTrail () {
        if (
          this.formItem.recordTrail &&
          this.formItem.recordTrail.position &&
          this.formItem.recordTrail.position !== '[]'
        ) {
          this.marker.moveAlong(eval(this.pathArray), 5000)
        }
      },
      /*画线方法*/
      drawLine (id, path) {
        /*默认轨迹*/
        var polyline = new AMap.Polyline({
          path: eval(path),
          strokeColor: '#2d8cf0', //线颜色--蓝色
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.recordMap.add(polyline)
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.recordMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.recordMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.recordMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.recordMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.recordMap.add(startMarker)
        this.recordMap.add(endMarker)
        this.recordMap.setFitView()
        this.handleRunTrail()
      },
      /*预警名称获取*/
      getWarningName (item) {
        if (item.typeId === '5') {
          return '断电预警-' + '位置：' + item.location
        } else if (item.typeId === '6') {
          return '低速预警-' + item.gpsStatus + '-位置：' + item.location
        } else if (item.typeId === '7') {
          return '高速预警-' + item.gpsStatus + '-位置：' + item.location
        } else {
          return item.gpsStatus + '预警' + '-位置：' + item.location
        }
      },
      /*画预警点*/
      drawWarningPoints () {
        console.info('------------', this.warningArray)
        this.warningArray.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(28, 28), // 图标尺寸
            image: 'http://files.fangshuoit.com/icon_warning_tag.png', // Icon的图像
            imageSize: new AMap.Size(28, 28) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(0, 0),
            title: this.getWarningName(x),
            icon: icon,
            angle: 0,
            zoom: 15,
            extData: {
              key: x.id
            }
          })
          marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker-info">' + x.gpsStatus + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            this.recordMap.add(marker)
            /*marker.on('click', this.showCarInfo)*/
            this.recordMap.setFitView()
          }
        })
      },
      viewInspectionOk () {
        this.xjDetailModal = false
      },
      closeInspectionModal () {
        this.xjDetailModal = false
      },
      closeDamageReportModal () {
        this.damageReportDetailModal = false
      },
      viewDamageReportOk () {
        this.damageReportDetailModal = false
      },
      viewInspectionDetail () {
        this.xjDetailModal = true
        const self = this
        self.$nextTick(() => {
          self.$refs.passedRef.loadData(self.formItem.instanceId)
        })
      },
      viewDamageReportDetail () {
        this.damageReportDetailModal = true
        const self = this
        self.$nextTick(() => {
          //   console.log('ceshi测试')
          self.$refs.viewRef.loadViewData(self.formItem.instanceId)
        })
      }
    }
  }
</script>
<style scoped>
  .car-marker-info {
    top: 2px;
    background-color: white;
    right: 0;
    padding: 3px;
    border: 1px solid #c1c2b9;
    border-radius: 4px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0em;
  }
</style>
