<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">巡检车辆查询</h1>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="制定人">
              <a-tree-select
                style="width: 150px;"
                allowClear
                :treeData="userList"
                v-model="managerUserStr"
                @change="beforeSearch"
                placeholder="请选择制定人"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="车辆名称">
              <a-select
                style="width: 150px;"
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfo"
                placeholder="请选择"
                @change="search"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="执行人">
              <a-tree-select
                style="width: 150px;"
                allowClear
                :treeData="userList"
                v-model="executeUserStr"
                @change="beforeSearch"
                placeholder="请选择执行人"
              >
              </a-tree-select>
            </a-form-item>
            <!--  <a-form-item label="任务状态">
               <a-radio-group @change="selectStatus" v-model="searchParams.status">
                 <a-radio-button value="">全部</a-radio-button>
                 <a-radio-button value="NOT">待执行</a-radio-button>
                 <a-radio-button value="DOING">执行中</a-radio-button>
                 <a-radio-button value="COMPLETED">已完成</a-radio-button>
               </a-radio-group>
             </a-form-item>
            <a-form-item label="任务类型">
               <a-radio-group @change="selectType" v-model="searchParams.type">
                 <a-radio-button value="">全部</a-radio-button>
                 <a-radio-button value="GIVEN">下达</a-radio-button>
                 <a-radio-button value="AUTO">自主</a-radio-button>
               </a-radio-group>
             </a-form-item>-->
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;">
              <a-form-item label="制定时间">
                <a-range-picker @change="dateOnChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-range-picker>
              </a-form-item>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="rowClick"
        :rowKey="(record) => record.id"
        bordered
        :scroll="{ x: 1500 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === 'NOT'">待执行</a-tag>
          <a-tag v-if="record.status === 'DOING'" color="#87d068">执行中</a-tag>
          <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type === 'GIVEN'">下达</span>
          <span v-if="record.type === 'AUTO'">自主</span>
        </template>
        <template slot="planTime" slot-scope="text, record">
          <span>{{ record.planStartTime }} ~ {{ record.planEndTime }}</span>
        </template>
        <template slot="executeTime" slot-scope="text, record">
          <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
        </template>
      </a-table>
    </a-card>
    <a-modal :title="platNumber"
             :visible="detailDrawer"
             :width="1400"
             centered
             :footer="null"
             @cancel="detailClose">
      <car-record-detail ref="viewRef"></car-record-detail>
    </a-modal>
    <!-- <a-drawer :title="platNumber"
               placement="right"
               :closable="true"
               @close="detailClose"
               width="450px"
               :mask="false"
               :visible="detailDrawer">
       <car-record-detail ref="viewRef"></car-record-detail>
     </a-drawer>-->
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { carRecordColumns, statusList, typeList } from './common/common'
  import CarRecordDetail from './Detail'

  export default {
    name: 'carRecordList',
    mixins: [entityCRUDWithCopy],
    components: { PageLayout, CarRecordDetail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.car.carRecordApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          managerUser: undefined,
          executeUser: undefined,
          carInfo: undefined,
          status: '',
          type: '',
          startTime: '',
          endTime: '',
          systemModule: 'xj_record',
        },
        initColumns: carRecordColumns(),
        statusList: statusList(),
        typeList: typeList(),
        orgTree: [],
        userList: [], //下发/制定人下拉数据
        carList: [],
        valueStyle: {
          color: '',
          textAlign: 'center',
        },
        statisticsData: {},
        dateSearch: null, //日期绑定数据
        platNumber: '', //车牌号
        expand: false,
        managerUserStr: undefined,
        executeUserStr: undefined
      }
    },
    created () {
      this.getOrgTree()
      this.loadUserList()
      this.loadCarInfoList()
    },
    methods: {
      selectStatus () {
        this.search()
      },
      selectType () {
        this.search()
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          },
        })
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          },
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          },
        })
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              if (!this.editModal) {
                this.platNumber = '车牌号：' + record.carInfo.platNumber
                this.handleView(record.id)
              }
            },
          },
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      //日期选择
      dateOnChange (date, dateString) {
        this.searchParams.startTime = dateString[0]
        this.searchParams.endTime = dateString[1]
        this.search()
      },
      /*重置*/
      resetForm () {
        this.managerUserStr = undefined
        this.executeUserStr = undefined
        this.dateSearch = null
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
        Object.assign(this.searchParams, this.initParams)
        this.search()
      },
      toggle () {
        this.expand = !this.expand
      },
      beforeSearch () {
        this.searchParams.managerUser = this.managerUserStr ? this.managerUserStr.split('_')[2] : undefined
        this.searchParams.executeUser = this.executeUserStr ? this.executeUserStr.split('_')[2] : undefined
        this.search()
      }
    },
  }
</script>

<style scoped></style>
